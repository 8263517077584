<template>
  <BaseReport
    :report="report"
    :attributes="ATTRIBUTES"
    report-class="bg-theme-5"
    resource="evaluation"
  >
    <template #subtitles>
      <div class="version">
        {{ $t("app.versions") }}
        {{ report?.evaluation?.version }}
      </div>
    </template>

    <template #status>
      <VChip
        :text="report?.evaluation?.evaluation_status ?? ''"
        :class="getStatusColor(report?.evaluation?.evaluation_status)"
      />
    </template>
  </BaseReport>
</template>

<script>
import { useI18n } from "vue-i18n";
// Composables
import useColor from "@/composables/useColor";
// Components
import BaseReport from "../BaseReport";
import VChip from "@/components/VChip";

export default {
  components: {
    BaseReport,
    VChip
  },
  props: {
    report: {
      type: Object,
      default: () => ({})
    }
  },
  setup() {
    // Misc
    const { t } = useI18n();

    // Composables
    const { getStatusColor } = useColor();

    // Constants
    const ATTRIBUTES = {
      duration: t("app.duration"),
      type: t("app.types"),
      passing_grade: t("app.passing_grade")
    };

    return {
      ATTRIBUTES,
      // useColor
      getStatusColor
    };
  }
};
</script>
