<template>
  <Filters @export="onClickExport" @search="onClickSearch">
    <div class="grid grid-cols-2 gap-2">
      <VSelect
        v-model="searchValues.user_type"
        :label="$t('app.user_type')"
        :options="userTypeOptions"
      />

      <VSelect
        v-model="searchValues.user_status"
        :label="$t('app.user_status')"
        :options="userStatusOptions"
      />
    </div>

    <div>
      {{ $t("app.registration_period") }}

      <div class="grid grid-cols-2 gap-2 mt-2">
        <VDatePicker v-model="searchValues.registration_period_start" />

        <VDatePicker v-model="searchValues.registration_period_end" />
      </div>
    </div>

    <div>
      {{ $t("app.completion_period") }}

      <div class="grid grid-cols-2 gap-2 mt-2">
        <VDatePicker v-model="searchValues.completion_period_start" />

        <VDatePicker v-model="searchValues.completion_period_end" />
      </div>
    </div>
  </Filters>
</template>

<script>
import { ref, computed } from "vue";
// Composables
import useOptions from "@/composables/useOptions";
// Components
import Filters from "@/components/templates/teaching/reports/Filters";
import VSelect from "@/components/inputs/VSelect";
import VDatePicker from "@/components/inputs/VDatePicker";

export default {
  components: {
    Filters,
    VSelect,
    VDatePicker
  },
  emits: ["export", "search"],
  setup(props, context) {
    // Composables
    const { ALL_OPTIONS } = useOptions();

    // Data
    const searchValues = ref({
      user_type: ALL_OPTIONS.INTERNAL.value,
      user_status: ALL_OPTIONS.ACTIVE.value,
      registration_period_start: "",
      registration_period_end: "",
      completion_period_start: "",
      completion_period_end: ""
    });

    // Computed
    const userTypeOptions = computed(() => [
      ALL_OPTIONS.INTERNAL,
      ALL_OPTIONS.EXTERNAL
    ]);

    const userStatusOptions = computed(() => [
      ALL_OPTIONS.ACTIVE,
      ALL_OPTIONS.INACTIVE,
      ALL_OPTIONS.DISABLED
    ]);

    // Methods
    const onClickExport = () => {
      context.emit("export", searchValues.value);
    };

    const onClickSearch = () => {
      context.emit("search", searchValues.value);
    };

    return {
      userTypeOptions,
      userStatusOptions,
      searchValues,
      onClickExport,
      onClickSearch
    };
  }
};
</script>
