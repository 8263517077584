<template>
  <BaseReport :report="report" report-class="bg-theme-5" resource="attestation">
    <template #subtitles>
      {{ details }}
    </template>
  </BaseReport>
</template>

<script>
import { computed } from "vue";
import { useStore } from "@/store";
// Components
import BaseReport from "../BaseReport";

export default {
  components: {
    BaseReport
  },
  props: {
    report: {
      type: Object,
      default: () => ({})
    }
  },
  setup(props) {
    // Misc
    const store = useStore();

    // Computed
    const lang = computed(() => store.getters["user/language"]);
    const attestation = computed(() => props.report?.attestation ?? {});
    // eslint-disable-next-line
    const details = computed(() => attestation.value?.name?.[lang.value]?.details);

    return {
      details
    };
  }
};
</script>
