<template>
  <div class="grid gap-12">
    <VTitle
      :title="$t('app.reports_failed_attempts', 2)"
      class="section-title"
    />

    <Filters @search="onClickSearch" @export="onClickExport" />

    <div v-if="isLoading" class="flex justify-center">
      <LoadingIcon icon="three-dots" class="w-8 h-8" />
    </div>

    <div
      v-else-if="!isLoading && Object.entries(data)?.length"
      class="boxed-tabs nav nav-tabs justify-end text-gray-600 dark:text-gray-500 rounded-md"
      role="tablist"
    >
      <VTab
        v-for="tab in TABS"
        :key="tab.value"
        :name="tab.value"
        :selected-tab="currentTab"
        class="btn border-0 shadow-none py-1.5 px-10 bg-gray-200 dark:bg-dark-1 end-tab"
        :label="tab.text"
        @click="updateTab"
      />
    </div>

    <div v-if="isCurrentTab(TABS.ATTESTATIONS.value)">
      <Attestation
        v-for="item in data?.attestations"
        :key="item?.id"
        :report="item"
        class="border-t border-dashed border-theme-15 py-12"
        :class="{ 'intro-y': hasAnimation }"
      />
    </div>

    <div v-if="isCurrentTab(TABS.COLLECTIONS.value)">
      <Collection
        v-for="item in data?.collections"
        :key="item?.id"
        :report="item"
        class="border-t border-dashed border-theme-15 py-12"
        :class="{ 'intro-y': hasAnimation }"
      />
    </div>

    <div v-if="isCurrentTab(TABS.EVALUATIONS.value)">
      <Evaluation
        v-for="item in data?.evaluations"
        :key="item?.id"
        :report="item"
        class="border-t border-dashed border-theme-15 py-12"
        :class="{ 'intro-y': hasAnimation }"
      />
    </div>

    <div v-if="isCurrentTab(TABS.TRAININGS.value)">
      <Report
        v-for="item in data?.trainings"
        :key="item?.training?.id"
        :report="item"
        report-class="bg-theme-5"
        class="border-t border-dashed border-theme-15 py-12"
        :class="{ 'intro-y': hasAnimation }"
      />
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import { useI18n } from "vue-i18n";
// Composables
import useRequest from "@/composables/useRequest";
import useDownload from "@/composables/useDownload";
import useTabs from "@/composables/useTabs";
import useAnimation from "@/composables/useAnimation";
// Components
import Filters from "./Filters";
import Attestation from "./Attestation";
import Collection from "./Collection";
import Evaluation from "./Evaluation";
import Report from "../trainings/Report";
import VTab from "@/components/VTab";
import VTitle from "@/components/VTitle";

export default {
  components: {
    Filters,
    Collection,
    Evaluation,
    Attestation,
    Report,
    VTab,
    VTitle
  },
  setup() {
    // Misc
    const { t } = useI18n();

    // Constants
    const ENDPOINT = "teaching.reports.failed-attempts";
    // eslint-disable-next-line
    const documentTitle = `${t("app.failed_attempts", 2)} - ${t("app.reports", 2)} - ${t("app.teaching")}`;

    const TABS = {
      ATTESTATIONS: {
        text: t("app.attestations", 2),
        value: "attestations"
      },
      COLLECTIONS: {
        text: t("app.collections", 2),
        value: "collections"
      },
      EVALUATIONS: {
        text: t("app.evaluations", 2),
        value: "evaluations"
      },
      TRAININGS: {
        text: t("app.trainings", 2),
        value: "trainings"
      }
    };

    // Composables
    // eslint-disable-next-line
    const { currentTab, isCurrentTab, updateTab } = useTabs(TABS.ATTESTATIONS.value);
    const { request } = useRequest();
    const { download } = useDownload();
    const { hasAnimation } = useAnimation();

    // Data
    const data = ref({});
    const isLoading = ref(false);

    // Methods
    const onClickExport = values => {
      download(ENDPOINT, {}, values);
    };

    const getReports = async data => {
      return await request({
        endpoint: ENDPOINT,
        data
      });
    };

    const onClickSearch = async values => {
      isLoading.value = true;
      const response = await getReports(values);

      data.value = response?.payload?.data ?? {};
      isLoading.value = false;
    };

    return {
      isLoading,
      TABS,
      data,
      documentTitle,
      onClickSearch,
      onClickExport,
      // useTabs
      isCurrentTab,
      currentTab,
      updateTab,
      // useAnimation
      hasAnimation
    };
  }
};
</script>
